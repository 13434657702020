import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class Header {
    constructor() {
        Header.swiper_banner()
        Header.langs_switcher()
    }

    static async swiper_banner() {
        const swiperKey = 'banner'
        const slideItem = `[data-swiper="${swiperKey}"] > .banner-slide`

        const options = {
            slidesPerView: 'auto',
            autoplay: {
                delay: 5000,
            },
            speed: 1500,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: '.swiper-banner-pagination',
                clickable: true,
            },
            navigation: {
                prevEl: '#swiper-banner-prev',
                nextEl: '#swiper-banner-next',
            },
            mousewheel: {
                forceToAxis: true,
            },
            grabCursor: true,
            loop: false,
        }

        $(`[data-swiper="${swiperKey}"]`).append(
            `<div class="swiper-actions"><div id="swiper-${swiperKey}-prev" class="hc-slider-button-prev"></div><div class="swiper-banner-pagination"></div><div id="swiper-${swiperKey}-next" class="hc-slider-button-next"></div></div>`,
        )
        $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

        await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
    }

    static langs_switcher() {
        const current_lang = $('.lang__current')
        const list_langs = $('.lang__list')

        const current_lang_value = current_lang.first().text()

        //Hide submenu
        list_langs.hide()

        //Open submenu
        $('.tools__item__languages').on('click', () => {
            $(list_langs).toggleClass('lang__list--open')
            $('.lang__list--open').fadeIn(250)
            $('.lang__list:not(.lang__list--open)').fadeOut(250)
        })

        //Remove current language on list (init)
        $('.lang__list > a').each(function () {
            if ($(this).text() === current_lang_value) {
                $(this).hide()
            }
        })

        //Change Navbar Language on click
        $('.lang__list > a').on('click', function () {
            $('.lang__list > a').show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }
}
